"use client";

import { useEffect, useRef } from "react";

export default function useScrollToFetchData(FetchMoreData: () => void) {
  const bottom = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const bottomCurrent = bottom.current;
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.8,
    };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          return;
        }
        FetchMoreData();
      });
    }, options);

    if (bottomCurrent) {
      observer.observe(bottomCurrent);
    }

    return () => {
      if (bottomCurrent) {
        observer.unobserve(bottomCurrent);
      }
    };
  }, [FetchMoreData]);

  return bottom;
}
