"use client";
import { logout } from "@/app/lib/actions";
import styles from "./styles.module.css";

export default function SignOutBtn() {
  const handleOnClick = async () => {
    await logout();
    window.location.replace("/");
  };

  return (
    <button className={styles.signOutBtn} onClick={handleOnClick}>
      Sign out
    </button>
  );
}
