import { useEffect, useRef } from "react";
import Link from "next/link";
import styles from "./styles.module.css";
import { INotificationCard, TStatus } from "@/app/types";
import Skeleton from "@/app/components/Skeleton";
import { IMAGE_PLACEHOLDER } from "@/app/utils/constants";

const parseJSON = (str: string) => {
  let parsedStr = {};
  try {
    parsedStr = JSON.parse(str);
    return parsedStr;
  } catch (e) {
    return {};
  }
};

type TJSONData = {
  avatar: string;
  dao_id: string;
  status: TStatus;
  // category: "dao" | "stats" | "news";
  category: 1 | 2 | 3;
};

export default function SmNotificationCardCard(props: INotificationCard) {
  const { rawData } = props;
  const { epoch, payload } = rawData;
  const { data } = payload;
  const { acta, additionalMeta, aimg, amsg, asub, icon } = data;
  const imgRef = useRef<HTMLImageElement>(null);
  const imgRefThumbnail = useRef<HTMLImageElement>(null);

  const details = parseJSON(additionalMeta?.data) as TJSONData;
  const category = Number(details?.category) || 0;
  const imgSrc = category === 1 ? details?.avatar : icon;
  const formattedTime = new Date(epoch).toLocaleString();

  useEffect(() => {
    if (!imgSrc) return;
    if (!imgRef?.current) return;

    const img = imgRef?.current;
    img.src = imgSrc;
    img.onload = () => {
      return;
    };
    img.onerror = () => {
      return (img.src = IMAGE_PLACEHOLDER);
    };

    if (category !== 3) return;
    if (!imgRefThumbnail?.current) return;

    const imgThumbnail = imgRefThumbnail?.current;
    imgThumbnail.src = aimg;
    imgThumbnail.onload = () => {
      return;
    };
    imgThumbnail.onerror = () => {
      return (imgThumbnail.src = IMAGE_PLACEHOLDER);
    };
  }, [imgSrc, aimg, category]);

  // TODO: [Phase 2] smNotification categories
  if (category === 1 || category === 3) {
    return (
      <Link href={acta} className={styles.smNotificationCard}>
        <img
          ref={imgRef}
          src={IMAGE_PLACEHOLDER}
          className={styles.smNotificationCardImg}
          alt="dao"
        ></img>
        <div className={styles.smNotificationCardContent}>
          <div className={styles.smNotificationCardAsub}>{asub}</div>
          <div className={styles.smNotificationCardAmsg}>{amsg}</div>
          <div className={styles.smNotificationCardTime}>{formattedTime}</div>
        </div>
      </Link>
    );
  }

  return null;
}

export function SmNotificationCardCardSkeleton() {
  return (
    <div className={styles.smNotificationCard}>
      <img
        className={styles.smNotificationCardImg}
        src={IMAGE_PLACEHOLDER}
        alt="dao"
      ></img>
      <div className={styles.smNotificationCardContent}>
        <Skeleton>
          <div className={styles.smNotificationCardAsub}>
            nofitication card title
          </div>
        </Skeleton>
        <Skeleton>
          <div className={styles.smNotificationCardAmsg}>
            nofitication message content
          </div>
        </Skeleton>
        <Skeleton>
          <div className={styles.smNotificationCardTime}>
            00/00/0000 00:00:00
          </div>
        </Skeleton>
      </div>
    </div>
  );
}
