"use client";
import { ModalProvider } from "./context/modal";
import { DataProvider } from "./context/data";

export function Providers({ children }: { children: React.ReactNode }) {
  return (
    <DataProvider>
      <ModalProvider>{children}</ModalProvider>
    </DataProvider>
  );
}
