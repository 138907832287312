"use client";
import { useContext, MouseEvent } from "react";
import { ModalContext } from "@/app/context/modal";
import styles from "./styles.module.css";

export default function SignInBtn() {
  const { handleModal } = useContext(ModalContext);

  const handleOnClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    handleModal("signin", null);
  };

  return (
    <button className={styles.signInBtn} onClick={handleOnClick}>
      Sign in
    </button>
  );
}
